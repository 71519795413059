<template>
    <!--  银行卡管理右边部分    -->
    <div style="width: 100%;height: auto;">
        <template>
            <el-tabs v-model="toActiveName" @tab-click="handleClick" type="border-card">
                <el-tab-pane v-if="user.userType == 1" label="银行卡管理" name="first">
                    <div v-for="(item,index) in cardList" :key="index" style="width: 215px;height: 130px;float: left;margin-right: 10px;margin-bottom: 10px;  border-radius: 5px 5px 5px 5px;border: 1px solid #DDDDDD;">
                        <div style="width: 100%;height: 20px;">
                            <div style="width: 120px;height: 20px;margin-top: 5px;margin-left: -15px;">
                                <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/kjzf.png" />
                            </div>
                        </div>
                        <div style="width: 100%;height: 46px;display: flex;font-size: 14px;color: #4D4D4D;">
                            <div style="width: 46px;height: 28px;margin-top: 9px;">
                                <img style="width: 100%;height: 100%;" :src="item.bankUrl" />
                            </div>
                            <div style="width: 120px;line-height: 46px;">中国{{item.bankName}}</div>
                            <div style="width: 80px;line-height: 46px;">尾号:{{item.cardId}}</div>
                        </div>
                        <div style="width: 205px;height: 60px;font-size: 12px;color: #4D4D4D;text-align: right;padding-right: 10px;color: #1D82FE;">
                            <div style="width: 100%;height: 30px;display: flex;">
                                <div @click="unbinding(index)" style="width: 100%;line-height: 30px;padding-top: 35px;cursor: pointer;">解绑</div>
                            </div>
                        </div>
                    </div>
                    <div style="width: 215px;height: 130px;float: left;margin-right: 10px;margin-bottom: 10px; font-size: 20px;color: #2970FF; font-weight: 600; border-radius: 5px 5px 5px 5px;border: 1px dashed #000; text-align: center">
                        <div class="hand op" style="margin-top: 30px"  @click="set_visibleFiles">
                            <div> <i class="el-icon-plus" style="font-size: 30px;font-weight: 600; margin-bottom: 10px"></i></div>
                            <div>添加银行卡</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="user.userType == 2" label="对公账户管理" name="second">
                    <div  v-if="JSON.stringify(corpBanCardInfo) != '{}'" style="width: 215px;height: 130px;float: left;margin-right: 10px;margin-bottom: 10px;  border-radius: 5px 5px 5px 5px;border: 1px solid #DDDDDD;">
                        <div style="width: 100%;height: 20px;">
                            <div style="width: 120px;height: 20px;margin-top: 5px;margin-left: -15px;">
                                <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/kjzf.png" />
                            </div>
                        </div>
                        <div style="width: 100%;height: 46px;display: flex;font-size: 14px;color: #4D4D4D;">
                            <div style="width: 46px;height: 28px;margin-top: 9px;">
                                <img style="width: 100%;height: 100%;" :src="corpBanCardInfo.bankUrl" />
                            </div>
                            <div style="width: 120px;line-height: 46px;">中国{{corpBanCardInfo.bankName}}</div>
                            <div style="width: 80px;line-height: 46px;">尾号:{{corpBanCardInfo.cardNo}}</div>
                        </div>
                        <div style="width: 205px;height: 60px;font-size: 12px;color: #4D4D4D;text-align: right;padding-right: 10px;color: #1D82FE;">
                            <div style="width: 100%;height: 30px;display: flex;">
<!--                                <div  style="width: 205px;line-height: 30px;padding-top: 35px;cursor: pointer;">解绑</div>-->
                            </div>
                        </div>
                    </div>
                    <div v-if="JSON.stringify(corpBanCardInfo) == '{}'" style="width: 215px;height: 130px;float: left;margin-right: 10px;margin-bottom: 10px; font-size: 20px;color: #2970FF; font-weight: 600; border-radius: 5px 5px 5px 5px;border: 1px dashed #000; text-align: center">
                        <div class="hand op" style="margin-top: 30px" @click="organDialog = true">
                            <div> <i class="el-icon-plus" style="font-size: 30px;font-weight: 600; margin-bottom: 10px"></i></div>
                            <div>添加对公账户</div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </template>

        <!--添加银行卡个人-->
        <el-dialog :show-close=false :visible.sync="visibleFiles" :close-on-click-modal="false">
            <div style="float: right;margin-right: 20px;" @click="closeType">
                <svg t="1678705360524" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2781" width="20" height="20"><path d="M512 0C229.187368 0 0 229.187368 0 512 0 794.812632 229.187368 1024 512 1024 794.812632 1024 1024 794.812632 1024 512 1024 229.187368 794.812632 0 512 0ZM512 970.105263C259.368421 970.105263 53.894737 764.631579 53.894737 512 53.894737 259.368421 259.368421 53.894737 512 53.894737 764.631579 53.894737 970.105263 259.368421 970.105263 512 970.105263 764.631579 764.631579 970.105263 512 970.105263ZM550.130526 512 719.629474 342.366316C730.273684 331.856842 730.273684 314.88 719.629474 304.370526 709.12 293.726316 692.143158 293.726316 681.633684 304.370526L512 473.869474 342.366316 304.370526C331.856842 293.726316 314.88 293.726316 304.370526 304.370526 293.726316 314.88 293.726316 331.856842 304.370526 342.366316L473.869474 512 304.370526 681.633684C293.726316 692.143158 293.726316 709.12 304.370526 719.629474 309.625263 724.884211 316.496842 727.578947 323.368421 727.578947 330.24 727.578947 337.111579 724.884211 342.366316 719.629474L512 550.130526 681.633684 719.629474C686.888421 724.884211 693.76 727.578947 700.631579 727.578947 707.503158 727.578947 714.374737 724.884211 719.629474 719.629474 730.273684 709.12 730.273684 692.143158 719.629474 681.633684L550.130526 512Z" p-id="2782"></path></svg>
            </div>
            <div style="height: 26px;font-size: 20px; font-weight: 600; margin: 10px 0 20px 0; text-align: center">
                <div>添加银行卡</div>
            </div>
            <div style="display: flex;margin: 10px 0 0 80px">
                <div style="width: 120px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">持卡人姓名：</div>
                <div style="width: 394px;height: 54px;margin-left: 56px">
                    <!--  <el-input v-model="holding" placeholder="请输入持卡人姓名"></el-input>-->
                    <div style="color: #1a1a1a;font-size: 20px;margin-top: 3px;font-weight: bold;">{{nameauth.realName}}</div>
                </div>
            </div>
            <div style="display: flex;margin: 10px 0 0 80px">
                <div style="width: 100px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">身份证号：</div>
                <div style="width: 394px;height: 54px;margin-left: 76px">
                    <div style="color: #1a1a1a;font-size: 20px;margin-top: 3px;font-weight: bold;">{{nameauth.idCardNo}}</div>
                </div>
            </div>
            <div style="display: flex;margin: 10px 0 0 80px">
                <div style="width: 120px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">银行卡卡号：</div>
                <div style="width: 394px;height: 54px;margin-left: 56px">
                    <el-input placeholder="请输入卡号" prefix-icon="el-icon-lock" v-model="wlke"></el-input>
                </div>
            </div>

            <div style="display: flex;margin: 20px 0 0 80px">
                <div style="width: 160px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">银行预留手机号：</div>
                <div style="width: 394px;height: 54px;margin-left: 16px">
                    <el-input oninput="if(value.length>11)value=value.slice(0,11)" v-model.trim="reserve"  name="reserve" :validate-event=true  placeholder="请输入手机号" class="input-with-select">
                        <template slot="prepend">+86</template>
                    </el-input>
                </div>
            </div>

            <div v-if="nameauth.bgbPayPwd == 0">
                <div style="display: flex;margin: 10px 0 0 80px">
                    <div style="width: 140px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">设置安全密码：</div>
                    <div style="width: 394px;height: 54px;margin-left: 36px">
                        <el-input type="password"placeholder="请输入卡号" maxlength="6" prefix-icon="el-icon-lock" v-model="payPwd1" show-password></el-input>
                    </div>
                </div>

                <div style="display: flex;margin: 10px 0 0 80px">
                    <div style="width: 140px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">确认安全密码：</div>
                    <div style="width: 394px;height: 54px;margin-left: 36px">
                        <el-input type="password"placeholder="请输入卡号" maxlength="6" prefix-icon="el-icon-lock" v-model="payPwd2" show-password></el-input>
                    </div>
                </div>
            </div>

            <div style="display: flex;margin: 20px 0 0 80px">
                <div style="width: 120px;height: 24px;font-size: 20px;color: #444444;font-weight: bold;">短信验证码：</div>
                <div style="width: 394px;height: 54px;margin-left: 16px;margin-left: 56px">
                    <el-input oninput="if(value.length>6)value=value.slice(0,6)" v-model.trim="code" name="code" :validate-event=true  placeholder="请输入短信验证码" >
                        <template slot="append" >
                            <button style="width: 100%;height: 30px;border: 0;background: #F5F7FA;cursor: pointer;" @click="getCode" :disabled="disabled">{{ codeString }}</button>
                        </template>
                    </el-input>
                </div>
            </div>

            <div style="display: flex;justify-content: center; margin-top: 20px">
                <!--    确定添加银行卡  -->
                <el-button type="primary" @click="confirmadd">确认添加</el-button>
            </div>
            <div style="height: 20px"></div>
        </el-dialog>

        <!--添加对公账户弹窗-->
        <el-dialog :show-close=false :visible.sync="organDialog" :close-on-click-modal="false">
            <addCorpCard :onClose="function (){organDialog = false;}"></addCorpCard>
        </el-dialog>
    </div>
</template>

<script>
    import addCorpCard from "@/components/common/Pay/organ/addCorpCard.vue";
    export default {
        name: "bankCard",
        components:{
            addCorpCard
        },
        computed:{
            user(){
                return this.$store.state.user;
            }
        },
        // 在 Vue 组件中使用 $watch 监听 Vuex 数据的变化
        watch: {
            user() {}
        },
        props:{
            activeName:String,
            default(){
                return '';
            }
        },
        data(){
            return{
                //tab标签字段
                toActiveName: this.activeName,
                visibleFiles:false,
                cardList:[],
                nameauth:{
                    realName:"未实名",
                    idCardNo:"",
                },
                wlke:'',
                reserve:"",
                code:'',
                disabled:false,
                codeString:'获取验证码',
                time:60,
                applyId:'',
                payPwd1:'',
                payPwd2:'',

                // 添加公账弹窗
                organDialog: false,
                corpBanCardInfo: {},
            }
        },
        mounted() {
            var that = this;
            that.getAuthInfo();
            if(this.user.userType == 1){
                this.activeName = 'first';
            }else if(this.user.userType == 2){
                this.activeName = 'second';
            }

            //支付监听
            this.$EventBus.$on("callbackNotice", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.getAdaPayBanCardList();
                    }
                });
            });

        },
        methods:{
            handleClick(tab, event) {
                console.log(tab, event);
            },

            //查询实名信息
            getAuthInfo(){
                var that = this;
                that.newApi.getAuthInfo({}).then((res) => {
                    that.nameauth = res.data;
                    that.getAdaPayBanCardList();
                    if(that.user.userType == 2){
                        that.getCorpBanCardInfo();
                    }
                })
            },

            // 获取银行卡列表
            getAdaPayBanCardList(){
                var that = this;
                that.newApi.getAdaPayBanCardList({}).then((res)=>{
                    that.cardList = res.data;
                    that.time = 0;
                    that.code = "";
                    that.wlke = "";
                    that.reserve = "";
                    that.visibleFiles = false;
                })
            },

            //获取对公账户详情
            getCorpBanCardInfo(){
                var that = this;
                that.newApi.getCorpBanCardInfo({}).then((res)=>{
                    that.corpBanCardInfo = res.data;
                })
            },


            //添加银行卡弹窗
            set_visibleFiles() {
                var that = this;
                if(that.user.realNameStatus != 2 ){
                    return that.utils.msg("请先完成实名认证");
                }else if(that.user.realNameStatus == 2){
                    that.visibleFiles = true;
                }
            },

            //关闭弹窗
            closeType(){
                var that =this;
                that.visibleFiles = false;
                that.time = 0;
            },

            //获取银行卡验证码
            getCode(){
                var that = this;
                if(this.nameauth.bgbPayPwd == 0){
                    if ((that.payPwd1 == "" || this.payPwd1.length < 6) || (that.payPwd2 == "" || this.payPwd2.length < 6)) {
                        this.utils.err("密码至少为6位字符");
                        return false;
                    }
                    if (this.payPwd1 !== this.payPwd2) {
                        this.utils.err("密码不一致");
                        return false;
                    }
                }

                var ret =  /^([1-9]{1})(\d{14}|\d{18})$/;
                if (that.wlke=='' || !ret.test(that.wlke)){
                    that.utils.err("请输入正确的的卡号");
                    return false;
                }
                var re = /^1[3|5|6|7|8|9][0-9]\d{8}$/;
                if (that.reserve=='' || !re.test(that.reserve)){
                    that.utils.err("手机号不正确1");
                    return false;
                }
                that.countdown();
                this.newApi.alpplyBankCard({cardId:that.wlke,mobile:that.reserve}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus("验证码已发送，请注意查收");
                        that.applyId = ret.data;
                        that.countdown();
                    }
                })
            },

            //添加银行卡提交
            confirmadd(){
                var that =this;
                if(this.nameauth.bgbPayPwd == 0){
                    if ((that.payPwd1 == "" || this.payPwd1.length < 6) || (that.payPwd2 == "" || this.payPwd2.length < 6)) {
                        this.utils.err("密码至少为6位字符");
                        return false;
                    }
                    if (this.payPwd1 !== this.payPwd2) {
                        this.utils.err("密码不一致");
                        return false;
                    }
                }
                var params = {};
                if(!that.checkedPhone()){
                    return false;
                }
                params.cardId = that.wlke;
                params.mobile = that.reserve;
                params.code = that.code;
                params.payPwd = that.payPwd1;
                params.applyId = that.applyId;
                params.cardType = 1;
                if (that.code==''){
                    that.utils.err("验证码不可为空");
                    return false;
                }
                that.alpplyBankCardDetermine(params);
            },

            // 创建快捷绑卡确认
            alpplyBankCardDetermine(params){
                var that= this;
                this.openLoading("处理中稍后");
                that.newApi.alpplyBankCardDetermine(params).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.getAdaPayBanCardList();
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },

            //检查绑卡参数
            checkedPhone(){
                var that = this;
                if (that.wlke == ""){
                    that.utils.err("请输入您的卡号");
                    return false;
                }
                if (that.reserve == ""){
                    that.utils.err("请填写手机号");
                    return false;
                } else {
                    var re = /^1[3|5|6|7|8|9][0-9]\d{8}$/;
                    if (that.reserve=='' || !re.test(that.reserve)){
                        that.utils.err("手机号不正确2");
                        return false;
                    }
                }
                return true;
            },

            //解绑银行卡
            unbinding(index) {
                var that = this;
                var tokenNo = that.cardList[index].tokenNo;
                let msg = "解绑当前银行卡";
                if(that.cardList.length == 1){
                    msg = "解绑全部银行卡后会影响提现功能是否继续？";
                }
                that.utils.confirm(msg,function () {
                    that.openLoading("处理中稍后");
                    that.unbindBankCard(tokenNo);
                })
            },

            // 删除快捷卡请求
            unbindBankCard(tokenNo){
                var that = this;
                var params = {};
                params.tokenNo = tokenNo;
                that.newApi.unbindBankCard(params).then((res) => {
                    if(res.isSuccess == 1){
                        that.utils.sus(res.data);
                        that.$emit('change');
                        that.getAdaPayBanCardList();
                    }
                })
            },

            //获取验证码倒计时
            countdown(){
                var that = this;
                if(that.time == 0) {
                    that.disabled = false;
                    that.codeString ='获取验证码';
                    that.time  = 60;
                    return;
                } else {
                    that.disabled = true;
                    that.codeString = that.time + '秒重新发送';
                    that.time --;
                }
                setTimeout(function(){
                    that.countdown();
                }, 1000)
            },
        }

    }
</script>

<style scoped>
    .old {
        background: linear-gradient(270deg, #07937D 0%, #16D5A9 100%);
    }
    .now {
        background:#A8A8A8;
    }
</style>
